.header {
  position: relative;
  background-image: url("./img/layers/bg.jpg");
  min-height: 1080px;
  background-size: auto;
  background-position: center -80px;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 40px;
  margin-bottom: -100px;
}
@media screen and (max-width: 930px) {
  .header {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    justify-content: space-around;
    margin-bottom: -17vh;
  }
}
.header .nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1335px;
  margin: 0 auto;
  align-items: center;
  padding-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 1335px) {
  .header .nav {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 930px) {
  .header .nav {
    flex-direction: row;
  }
}
@media screen and (max-width: 480px) {
  .header .nav {
    flex-direction: column;
    margin-top: -18vh;
  }
}
.header .nav .logo img {
  image-rendering: pixelated;
}
@media screen and (max-width: 930px) {
  .header .nav .logo img {
    max-width: 150px;
  }
}
.header .nav .menu ul {
  gap: 40px;
  font-size: 14px;
}
@media screen and (max-width: 1055px) {
  .header .nav .menu ul {
    gap: 20px;
    font-size: 12px;
  }
}
.header .nav .menu ul a {
  text-decoration: none;
  color: #fff;
  transition: all ease-in-out 0.2s;
}
.header .nav .menu ul a:hover {
  color: #ff5810;
}
@media screen and (max-width: 930px) {
  .header .nav .menu {
    display: none;
  }
}
.header .nav ul {
  display: flex;
  list-style: none;
  gap: 10px;
}
@media screen and (max-width: 480px) {
  .header .nav ul {
    display: none;
  }
}
.header .content {
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  top: -188px;
}
@media screen and (max-width: 1480px) {
  .header .content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .header .content {
    margin-top: 22vh;
  }
}
.header .content h2 {
  color: #fff;
  background: #F0DBE7;
  background: linear-gradient(to right, #F0DBE7 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  text-shadow: 0 0 9px rgba(28, 56, 136, 0.86);
}
@media screen and (max-width: 600px) {
  .header .content h2 {
    font-size: 22px;
  }
}
.header .content p {
  max-width: 750px;
  margin: auto;
  padding: 20px 0 40px 0;
}
.header .content p .button {
  background-color: transparent;
  background-image: url("./img/btn.png");
  background-size: cover;
  border: 0;
  border-radius: 20px;
  width: 245px;
  height: 78px;
  font-size: 23px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all ease-in-out 0.2s !important;
}
.header .content p .button:hover {
  background-image: url("./img/btn2.png");
}
@media screen and (max-width: 600px) {
  .header .content p .button:hover {
    font-size: 15px;
    background: rgb(255, 78, 10);
    background: linear-gradient(24deg, rgb(255, 78, 10) 0%, #ffa848 100%);
    width: 50vw;
    height: 41px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 600px) {
  .header .content p .button {
    font-size: 15px;
    background: rgb(255, 78, 10);
    background: linear-gradient(24deg, #ffa848 0%, rgb(255, 78, 10) 100%);
    width: 50vw;
    height: 41px;
    border-radius: 10px;
  }
}
.header .baner-video-1 {
  position: absolute;
  width: 240px;
  top: 326px;
  left: -270px;
  right: 0;
  margin: auto;
  z-index: 2;
  transform: rotate(7deg);
}
.header .baner-video-1 video {
  border: 2px solid #16368c;
  border-radius: 4px;
}
@media screen and (max-width: 700px) {
  .header .baner-video-1 {
    left: -197px;
    top: 338px;
    width: 151px;
  }
}
.header .baner-video-2 {
  position: absolute;
  width: 240px;
  top: 326px;
  left: 0;
  right: -266px;
  margin: auto;
  z-index: 2;
  transform: rotate(-7deg);
}
.header .baner-video-2 video {
  border: 2px solid #16368c;
  border-radius: 4px;
}
@media screen and (max-width: 700px) {
  .header .baner-video-2 {
    right: -188px;
    top: 338px;
    width: 151px;
  }
}
.header .baners {
  display: none;
  position: absolute;
  width: 100%;
  height: 226px;
  background-size: auto;
  background-position: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 286px;
  background-repeat: no-repeat;
  background-image: url("./img/layers/baner.png");
  z-index: 2;
}
.header .kibice {
  position: absolute;
  width: 100%;
  height: 284px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 450px;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/layers/kibice.png");
  z-index: 1;
}
.header .konsola {
  position: absolute;
  width: 100%;
  height: 462px;
  background-size: auto;
  background-position: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 495px;
  background-image: url("./img/layers/konsola.png");
  z-index: 2;
}
.header .kura {
  background-image: url("./img/layers/kura.png");
  position: absolute;
  width: 100%;
  height: 661px;
  background-size: auto;
  background-position: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 241px;
  background-repeat: no-repeat;
  z-index: 4;
}
.header .swiatlo {
  position: absolute;
  width: 100%;
  height: 226px;
  background-size: auto;
  background-position: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 286px;
  background-repeat: no-repeat;
  background-image: url("./img/layers/swiatlo.png");
  z-index: 3;
}/*# sourceMappingURL=style.css.map */