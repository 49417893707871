.features {
  width: 100%;
  background-position: top center;
  background-image: url("./img/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1000px;
}
.features .features-row {
  display: flex;
  justify-content: center;
  gap: 13px;
  padding: 40px 0;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px) {
  .features .logos img {
    max-width: 40vw;
    height: auto;
  }
}
.features .galery {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  grid-template-areas: ". ." ". .";
  max-width: 1000px;
  margin: auto;
}
@media screen and (max-width: 1090px) {
  .features .galery {
    padding: 0 20px;
    margin-bottom: 60px;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    grid-template-areas: ".";
    max-width: 1000px;
    margin: auto;
    justify-items: center;
  }
}
@media screen and (max-width: 600px) {
  .features .galery {
    padding: 0 20px;
    margin-bottom: 60px;
    grid-auto-columns: auto;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
@media screen and (max-width: 600px) {
  .features .galery .video-thumbnail {
    width: 100% !important;
    height: 50vw !important;
  }
}
.features .galery .video-thumbnail video {
  border-radius: 20px !important;
  border: 2px solid #fcd617;
}
@media screen and (max-width: 600px) {
  .features .galery .video-thumbnail video {
    height: 50vw !important;
  }
}
.features .trailer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  gap: 40px;
}
.features .trailer .h3 {
  padding-bottom: 30px !important;
}
.features .trailer .player-wrapper {
  position: relative;
  margin-bottom: 70px;
}
.features .trailer .player-wrapper .react-player {
  width: 750px !important;
}
@media screen and (max-width: 780px) {
  .features .trailer .player-wrapper .react-player {
    width: 100% !important;
    height: auto !important;
  }
}

.modal-video-body {
  max-width: 90vw;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.section-2 {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}
@media screen and (max-width: 1480px) {
  .section-2 .mapa {
    width: 90%;
    margin: auto;
  }
}
.section-2 .characters-row {
  display: flex;
  justify-content: center;
  gap: 0;
  padding: 40px 0;
  max-width: 1750px;
  margin: auto;
}
@media screen and (max-width: 1480px) {
  .section-2 .characters-row {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "main1 main2 main3 main4" ". main5 main6 .";
  }
}
@media screen and (max-width: 600px) {
  .section-2 .characters-row {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "main2 main3" "main1 main4" "main5 main6" ". .";
    padding: 0;
  }
}
@media screen and (max-width: 350px) {
  .section-2 .characters-row {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    gap: 50px;
    grid-template-areas: "main2" "main3" "main4" "main1" "main5" "main6" ".";
  }
}
.section-2 .characters-row .main1 {
  grid-area: main1;
}
.section-2 .characters-row .main2 {
  grid-area: main2;
}
.section-2 .characters-row .main3 {
  grid-area: main3;
}
.section-2 .characters-row .main4 {
  grid-area: main4;
}
.section-2 .characters-row .main5 {
  grid-area: main5;
}
.section-2 .characters-row .main6 {
  grid-area: main6;
}
.section-2 .characters-row .item {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
}
.section-2 .characters-row .item span {
  color: #f6c111;
  padding-top: 5px;
  margin-bottom: 20px;
}
.section-2 .characters-row .item .hero {
  width: 100%;
  max-width: 210px;
}
@media screen and (max-width: 500px) {
  .section-2 .characters-row .item .hero {
    width: 45vw;
  }
}
@media screen and (max-width: 350px) {
  .section-2 .characters-row .item .hero {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .section-2 .characters-row .item .clogo {
    width: 50px;
  }
}
.section-2 .characters-row .item img {
  image-rendering: pixelated;
}/*# sourceMappingURL=style.css.map */