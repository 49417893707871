.weekly{
  background-position: top center;
  background-image: url('../features/img/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2{
    margin-top: 40px;
  }
  h3{
    margin: 60px 0;
  }
  p{
    max-width: 950px;
    margin: auto;
    b{
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      width: 100%;
      justify-content: center;
    }
    span{
      display: flex;
      justify-content: center;

      margin-bottom: 5px;
      color: #f6c111;  
      a{
        color: #f6c111;  
        padding: 0 5px;
      }
    }
    img{
      opacity: 1;
      width: 100%;
    }
  }
  
  img{
    @media screen and (max-width: 1480px) {
      width: 90%;
      margin: auto;
    }
  }
}

.graph-desktop{
  display: block;
    @media screen and (max-width: 600px) {
      display: none;
    }
}
.graph-mobile{
  display: none;
    @media screen and (max-width: 600px) {
      display: block;
      width: 70% !important;
    }
}