.features{
  width: 100%;
  background-position: top center;
  background-image: url('./img/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1000px;
  .features-row{
    display: flex;
    justify-content: center;
    gap: 13px;
    padding: 40px 0;
    flex-wrap: wrap;


  }
  .logos{
    img{
      @media screen and (max-width: 600px) {
        max-width: 40vw;
        height: auto;
      }
    }
  }
  .galery{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 20px; 
    grid-template-areas: 
      ". ."
      ". .";
    max-width: 1000px;  
    margin: auto;


    @media screen and (max-width: 1090px) {
      padding: 0 20px;
      margin-bottom: 60px;
      grid-auto-columns: 1fr; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 20px; 
      grid-template-areas: 
        ".";
      max-width: 1000px;  
      margin: auto;
      justify-items: center;
    }
    @media screen and (max-width: 600px) {
      padding: 0 20px;
      margin-bottom: 60px;
      grid-auto-columns: auto;
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }

    .video-thumbnail{
      @media screen and (max-width: 600px) {
        width: 100% !important;
        height: calc(90vw / 1.8) !important
      }
      video{
        border-radius: 20px !important;
        border: 2px solid #fcd617;
        @media screen and (max-width: 600px) {
          height: calc(90vw / 1.8) !important;
        }
      }
    }

  }
  .trailer{
    .h3{
      padding-bottom: 30px !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    gap: 40px;

    .player-wrapper {
      position: relative;
      margin-bottom: 70px;
      .react-player {
        width: 750px !important;

        @media screen and (max-width: 780px) {
          width: 100% !important;
          height: auto !important;
        }

      }

    }
    
  }


}

.modal-video-body {
  max-width: 90vw;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.section-2{
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./img/bg3.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;

  .mapa{
    @media screen and (max-width: 1480px) {
      width: 90%;
      margin: auto;
    }
  }
  
  .characters-row{
    display: flex;
    justify-content: center;
    gap: 0;
    padding: 40px 0;
    max-width: 1750px;
    margin: auto;

    @media screen and (max-width: 1480px) {
      display: grid; 
      grid-template-columns: auto auto auto auto; 
      grid-template-rows: 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
      "main1 main2 main3 main4"
      ". main5 main6 ."; 
    }

    @media screen and (max-width: 600px) {
      display: grid; 
      grid-template-columns: auto auto; 
      grid-template-rows: 1fr 1fr 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
      "main2 main3"
      "main1 main4"
      "main5 main6"
      ". ."; 
      padding: 0;
    }

    @media screen and (max-width: 350px) {
      display: grid; 
      grid-template-columns: auto; 
      grid-template-rows: 1fr; 
      gap: 50px; 
      grid-template-areas: 
      "main2"
      "main3"
      "main4"
      "main1"
      "main5"
      "main6"
      "."; 
    }

    .main1 { grid-area: main1; }
    .main2 { grid-area: main2; }
    .main3 { grid-area: main3; }
    .main4 { grid-area: main4; }
    .main5 { grid-area: main5; }
    .main6 { grid-area: main6; }

    .item{
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      align-items: center;
      span{
        color: #f6c111;
        padding-top: 5px;
        margin-bottom: 20px;
      }
      .hero{
        width: 100%;
        max-width: 210px;
        @media screen and (max-width: 500px) {
          width: 45vw;
        }
        @media screen and (max-width: 350px) {
          width: 90%;
        }
      }
      .clogo{
        @media screen and (max-width: 600px) {
          width: 50px;
        }
      }
      img{
        image-rendering: pixelated;
      }      
    }

  }

}