.follow{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 70px auto 50px auto;
  align-items: center;
  
  ul{
    display: flex;
    list-style: none;
    gap: 10px;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  a{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: #fdce09;
  }
}