.weekly {
  background-position: top center;
  background-image: url("../features/img/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.weekly h2 {
  margin-top: 40px;
}
.weekly h3 {
  margin: 60px 0;
}
.weekly p {
  max-width: 950px;
  margin: auto;
}
.weekly p b {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.weekly p span {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  color: #f6c111;
}
.weekly p span a {
  color: #f6c111;
  padding: 0 5px;
}
.weekly p img {
  opacity: 1;
  width: 100%;
}
@media screen and (max-width: 1480px) {
  .weekly img {
    width: 90%;
    margin: auto;
  }
}

.graph-desktop {
  display: block;
}
@media screen and (max-width: 600px) {
  .graph-desktop {
    display: none;
  }
}

.graph-mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .graph-mobile {
    display: block;
    width: 70% !important;
  }
}/*# sourceMappingURL=style.css.map */