@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap');
@font-face {
  font-family: "GOTHAM-BLACK";
  src:
    local("GOTHAM-BLACK"),
    url("./font/gotham/GOTHAM-BLACK.OTF") format("otf"),
}
@font-face {
  font-family: "GOTHAM-BOOK";
  src:
    local("GOTHAM-BOOK"),
    url("./font/gotham/GOTHAM-BOOK.OTF") format("otf"),
}
@font-face {
  font-family: "GOTHAM-LIGHT";
  src:
    local("GOTHAM-LIGHT"),
    url("./font/gotham/GOTHAM-LIGHT.otf") format("otf"),
}
@font-face {
  font-family: "GOTHAM-MEDIUM";
  src:
    local("GOTHAM-MEDIUM"),
    url("./font/gotham/GOTHAM-MEDIUM.OTF") format("otf"),
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html {
  scroll-behavior:smooth;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  color: #fff;
  min-height: 100vh ;
  text-align: center;
  h2 {
    font-family: "Antonio", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 48px;
    color: #fdce09;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 1055px) {
      font-size: 38px;
    }
    @media screen and (max-width: 600px) {
      font-size: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 400px) {
      font-size: 35px;
    }
    br{
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
  h3{
    font-family: "Antonio", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    color: #ffffff;
    opacity: .7;
    padding: 0;
    margin: 80px 0 0 0;
    @media screen and (max-width: 600px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 480px) {
      font-size: 22px;
    } 
    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  }
  p{
    font-family: "Open Sans", sans-serif;
    padding: 10px 0 20px 0;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    @media screen and (max-width: 600px) {
      font-size: 11px;
      line-height: 17px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 400px) {
      font-size: 10px;
      line-height: 1.4;
    }
    
  }


}

.content{
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
}

